import React from "react";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import { Container } from "../Elements/Container";
import { Link } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import ButtonLink from "./buttonInformation";

import logoDrCastro from "../img/dr-castor-garaban-logos.svg";
import logoDrFilomena from "../img/dra-filomena-montemurro-logos.svg";
import logoJoseMiguel from "../img/dr-jose-miguel-gomez-logos.svg";
import logoJavierMartines from "../img/dr-javier-martinez-logos.svg";
import logoVianka from "../img/dra-vianka-torres-logos.svg";

const StyledContent = styled(Container)`
  
@media screen and (max-width: 768px) {
  ${(props) => props.templateKey !== "clinic-page" && 
    props.templateKey !== "professionals-page" &&
  `margin-top: 34px;`}
  margin-bottom: 40px;
  margin-top: -1rem;
}
  ${(props) =>
    props.contained &&
    `
  

    @media screen and (min-width: 768px) {
      
      .paragraph-item{
        &.odd {
        padding-left: 5vw;
      }
      &.even {
        padding-right: 5vw;
      }
      }

      .paragraph-item{
        margin-top: -80px;
      }    
    
      padding-top: ${rhythm(2)}!important;
      padding-bottom: ${rhythm(2)}!important;
      
      .small {
        min-height: 50vh !important;
        &:before {
          background-size: 170% !important;
          background-position: center !important;
        }
        &:after {
          background-size: 170% !important;
          background-position: center !important;
        }
      }
    }
    @media screen and (min-width: 1200px) {
      .small {
        &:before {
          background-size: contain !important;
        }
        &:after {
          background-size: contain !important;
        }
      }
    }
  `}
  ${(props) =>
    props.enforce &&
    ` ${
      props.contained &&
      props.enforce &&
      `
      
      .small {
        .content-wrapper {
          opacity: 0;
        }
        &:before {
          background-size: contain !important;
        }
        &:after {
          background-size: contain !important;
        }
      }`
    }`}
  padding: 0;
  .link-box {
    display: flex;
    flex-flow: wrap;
  }
  .link {
    min-width: 170px;
    white-space: nowrap;
    text-align: center;
    width: fit-content;
    cursor: pointer;
    font-weight: 700;
    padding: 10px 20px;
    color: #222 !important;
    border: 1px solid #222;
    text-transform: uppercase;
    background-color: #fff;
    text-decoration: none !important;
    margin-bottom: ${rhythm(1)} !important;
    &:hover {
      background-color: #222 !important;
      color: #fff !important;
    }
  }
  .icon {
    width: 100%;
    padding-bottom: 40px;
    margin-bottom: -0.4rem !important;
    &:before,
    :after {
      background-size: contain !important;
      background-position: left !important;
    }
  }
  ul {
    margin-left: 0;
    li {
      &.nl {
        padding-left: 0;
      }
      line-height: 1.2;
      @media (min-width: 768px){
        font-size: 15px !important;
        line-height: 15px;
      }
      padding-left: 2rem;
      position: relative;
      list-style: none;
      .icon-icono-check {
        border-radius: 50%;
        padding: 2px;
        margin-left: -40px;
        margin-right: 7px;
      }
    }
  }
  
  .boxed {
    width: fit-content;
    padding: 5px 5px 0px 5px;
    letter-spacing: 2px;
    background: #333;
    color: #fff;
    font-size: 16px !important;
  }
  @media (min-width: 768px){
    .boxed{
      font-size: 18px !important;
    }
  }
  .light {
    font-weight: 300;
    margin-bottom: 9px;
  }
  .bebas {
    font-size: 20px;
    font-family: "Bebas Neue Bold";
  }
  hr {
    display: none;
  }
  @media screen and (max-width: 768px) {
    hr {
      display: none !important;
    }
  }

  .even .paragraph-item {
    
  }
  .paragraph-item {
    
    @media (max-width: 767px){
        padding: 0px 5vw !important;
    }
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    &:nth-of-type(1) {
      hr {
        position: absolute;
        width: calc(50% - 10vw);
        margin: 0;
        &.top {
          display: block;
          top: 0;
        }
        &.mapa {
          display: block;
          top: 0;
        }
      }
    }
    &:nth-last-of-type(1) {
      hr {
        position: absolute;
        width: calc(50% - 10vw);
        margin: 0;
        &.bottom {
          display: block;
          bottom: 0;
        }
      }
    }

    &:nth-of-type(odd) {
      flex-direction: row-reverse !important;
      @media screen and (max-width: 768px) {
        flex-direction: column !important;
      }
    }

    @media screen and (max-width: 768px) {
      &:nth-last-of-type(1n) {
        
      }
      /*padding: 0 5vw !important; */
      flex-direction: column !important;
      /*padding: 0px 5vw !important; */
      margin-top: 40px;

      .content-wrapper {
        padding: 0 !important; /
        max-width: 100% !important;
        h2,
        h1 {
          margin-top: ${rhythm(2)};
          span {
            height: 35px !important;
            width: 35px !important;
            font-size: 1.5em !important;
            margin-right: 10px !important;
          }
        }
      }
      .image {
        min-height: 250px !important;
        .content-wrapper {
          display: none !important;
        }
      }
    }

    .image {
      width: 100%;
      min-height: 50vh;
      display: flex;

      @media screen and (min-width: 1024px) {
        min-height: ${(props) => (!props.small ? "70vh" : "40vh")};
      }
      @media screen and (min-width: 1024px) {
        .content-wrapper {
          margin-top: ${rhythm(3)} !important;
          margin-bottom: ${rhythm(2)} !important;
        }
      }

      .content-wrapper {
        margin-top: ${rhythm(2)};
        margin-bottom: ${rhythm(1)};
        display: block;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        max-width: 100%;
        pointer-events: none;
      }
    }
    .content-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 5vw;
      max-width: 50%;
      h2,
      h1 {
        display: flex;
        align-items: center;
        margin-top: ${rhythm(1)};
        span {
          color: #91c508;
          height: 70px;
          width: 70px;
          display: flex;
          float: left;
          justify-content: center;
          align-items: center;
          font-size: 2em;
          margin-right: 15px;
          border-radius: 50%;
        }
      }
    }
  }
`;

const ElementParagraph = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px){
    padding: 0 5vw;
  }
  max-width: 50%;
  h2, h1 {
        display: flex;
        align-items: center;
        margin-top: 0.6rem;
        span {
          color: #91c508;
          height: 70px;
          width: 70px;
          display: flex;
          float: left;
          justify-content: center;
          align-items: center;
          font-size: 2em;
          margin-right: 15px;
          border-radius: 50%;
        }
      }
    }
  }

  p {
    margin-top: -14px;
  }

  i {
    
  }

  @media (max-width: 768px){
    /*padding: 0px !important; */
    max-width: 100% !important;
  }
`;

const ButtonContainer = styled.div`

  @media (max-width: 768px){
    margin-top: 12px;
  }
`;

const IconContainer = styled.div`

  @media (max-width: 768px){
    margin-top: -33px;
  }
`;

const LogoUniversity = styled.img`

  width: 80%;
  margin-top: -1rem;

  @media (min-width: 1024px){
    width: 50%;
  }

`;

const showUniveristyLogos = (index) => {

  switch(index) {
    case 0:
      return <LogoUniversity  src={logoDrCastro} />
    case 1:
      return <LogoUniversity  src={logoDrFilomena} />
    case 2:
      return <LogoUniversity  src={logoJavierMartines} />
    case 3:
      return <LogoUniversity  src={logoJoseMiguel} />
    case 4:
      return <LogoUniversity  src={logoVianka} />
      break;      
  }
}

const ParagraphProfessionals = (props) => {
  
  return (
    <StyledContent
      small={props.small}
      contained={props.contained}
      flexDirection="column"
      color="none"
      enforce={props.enforce}
      top={props.top}
      templateKey={props.templateKey}
    >
      {props.items.map((i, k) => {
        return (
          <div
            key={k}
            className={`${
              props.contained && (k + 1) % 2 === 0 ? "odd" : "even"
            } paragraph-item`}
          >
            {i.img && (
              <>
                <BackgroundImage critical={true}
                                className={`image ${props.contained && "small"} paragraph-img`}
                                fluid={i.img.childImageSharp.fluid}
                >
                    <div className="content-wrapper">
                      {ReactHtmlParser(i.content)}
                    </div>
                </BackgroundImage>
              </>
            )}
            <ElementParagraph>
                <>
                  <hr className="top"></hr>
                  {ReactHtmlParser(i.content)}
                  {showUniveristyLogos(i.index)}

                  {props.bottom && <hr className="bottom"></hr>}
                </>
            </ElementParagraph>
          </div>
        );
      })}
    </StyledContent>
  );
};

export default ParagraphProfessionals;
