import React, {useState, useLayoutEffect} from "react";
import Layout from "../layout";
import SetLang from "../components/setLang";
import Boxes from "../components/boxes";
import Hero from "../components/hero";
//import Heading from "../components/heading";
import HeadingSpecial from "../components/headingSpecial";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SEO from "../components/seo";
//import Paragraph from "../components/asideParagrah";
import ParagraphProfessionals from "../components/asideParagraphProfessionals";
import styled from "styled-components";
import {  rhythm } from "../utils/typography";
import Form from "../components/form";
import ReactHtmlParser from "react-html-parser";
import { ContactWidget } from "../components/ContactWidget";
import { ContactPopUp } from "../components/contactPopUp";
import { SpecialtiesForm } from "../components/specialtiesForm";

const Personal = styled.section`
  .title {
    padding: ${rhythm(3)} 5vw ${rhythm(2)} !important;
    text-align: center;
    background: #ededed;
    h1 {
      font-weight: 300;
    }
  }
  .cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 3rem calc(5vw - ${rhythm(1)}) 3rem;

    @media (max-width: 1024px) {
      .card {
        flex-basis: 50% !important;
      }
    }
    @media (max-width: 1024px) {
      .card {
        flex-basis: 50% !important;
      }
    }
    @media (max-width: 680px) {
      .card {
        flex-basis: 100% !important;
      }
    }
    .wrapper {
      max-width: 370px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      padding: 0 10px;
      flex-direction: column;
      text-align: center;
      border: 1px solid #ccc;
      border-top: 4px solid #5f5f5f;
      h5 {
        font-size: 1rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
    .card {
      display: flex;
      justify-content: center;
      flex-basis: 33.33%;
      -ms-flex: auto;
      position: relative;
      padding: ${rhythm(1)};
      box-sizing: border-box;
      z-index: 1;
      flex-direction: column;
      text-align: center;
      .image {
        position: relative;
        overflow: hidden;
        max-width: 225px;
        display: flex;
        justify-self: center;
        align-self: center;
        width: 60%;
        min-width: 150px;
        margin-bottom: ${rhythm(1)};
        margin-top: ${rhythm(1)};
      }

      .info {
        p {
          margin-top: -1rem;
        }
      }
    } 
  }
  .paragraph-item{
    padding: 0px 5vw !important;
  }

`;
export const ProffesionalsPageTemplate = ({
  templateKey,
  language,
  staff,
  hero,
  heading,
  procedures,
  professionals,
  form,
  forms,
}) => {
  return (
    <div>
      <Hero className="center single half" {...hero}></Hero>
      <HeadingSpecial {...heading} />
      <ParagraphProfessionals top={true} {...professionals} templateKey={templateKey} />
      <Personal>
        <div className="title">{ReactHtmlParser(staff.title)}</div>

        <div className="cards">
          {staff.cards.map((i, k) => {
            return (
              <div key={k} className="card">
                <div className="wrapper">
                  <Img critical={true} className="image" fluid={i.img.childImageSharp.fluid} />
                  {ReactHtmlParser(i.content)}
                </div>
              </div>
            );
          })}
        </div>
      </Personal>
      <SpecialtiesForm
          data={forms.specialties}
          title={form.title}
          language={language}
          img={form.background}>
      </SpecialtiesForm>
      <Boxes {...procedures}></Boxes>
    </div>
  );
};

const ProffesionalsPage = ({ data }) => {

  
  const {
    templateKey,
    language,
    title,
    description,
    keywords,
    redirects,
    hero,
    staff,
    form,
    heading,
    plainparallax,
    procedures,
    professionals,
  } = data.markdownRemark.frontmatter;

  const [isContactWidgetOpen, setIsContactWidgetOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPortrait,setIsPortrait] = useState(false);

  const changeOpenWidget = () => {
   /* if(isMobile){
      if(isPortrait){
        setIsContactWidgetOpen(!isContactWidgetOpen);
      }
    }else{
      setIsContactWidgetOpen(!isContactWidgetOpen);
    } */
    setIsContactWidgetOpen(!isContactWidgetOpen);
  };

  useLayoutEffect(() => {

    if(typeof window !== "undefined"){
      
      const updateSize = () => {

          let width = window.screen.width;
          let height = window.screen.height;

          if(width < 1026){
       
            setIsMobile(true);
          }else{
    
            setIsMobile(false);
          }

          if(height > width){
    
            setIsPortrait(true);
          }else{
         
            setIsPortrait(false);
          }
      };

      window.addEventListener("resize",updateSize);
    }
  });

  return (
    <Layout>
      <SetLang language={language} link={redirects} />
      <SEO
        title={title}
        lang={language}
        description={description}
        keywords={keywords}
      />
      <ProffesionalsPageTemplate
        {...{
          templateKey,
          language,
          title,
          redirects,
          hero,
          staff,
          heading,
          plainparallax,
          procedures,
          form,
          professionals,
        }}
      />

      <div>
        <ContactWidget changeOpenWidget={changeOpenWidget}/>

        {isContactWidgetOpen == true ? (
          <>
           <ContactPopUp changeOpenWidget={changeOpenWidget} />
          </>
        ) : <></>}

      </div>
    </Layout>
  );
};

export default ProffesionalsPage;


export const pageQuery = graphql`
  query ProffesionalsPage($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "professionals-page" } }
    ) {
      frontmatter {
        templateKey
        language
        title
        description
        keywords
        redirects
        heading {
          display
          content
        }
        form {
          title
          background {
            childImageSharp {
              fluid(srcSetBreakpoints: [1500], quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hero {
          background {
            scaleOnReveal
            img {
              childImageSharp {
                fluid(quality: 100, srcSetBreakpoints: [1500]) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            isParallax
          }
          anim {
            display
            type
          }
          height
          indicator
          portraitPosition
          content {
            position
            body
          }
        }
        staff {
          title
          cards {
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [200], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            content
          }
        }
        professionals {
          display
          items {
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [800], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            index
            content
            footer {
              icon {
                display
                img {
                  childImageSharp {
                    fluid(srcSetBreakpoints: [400], quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              link {
                display
                to
                placeholder
              }
            }
          }
        }

        procedures {
          title
          procedures {
            title
            to
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [550], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`; 
